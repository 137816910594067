import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'
// import { refresh } from 'redux/auth/auth-operations'

// import Loader from 'components/common/Loader'

const PublicRoute = () => {
  // const dispatch = useDispatch()
  const { isLogin } = useSelector((state) => state.auth)
  // const redirectUrl = Cookies.get('redirectUrl')

  const refreshToken = Cookies.get('refreshToken')

  useEffect(() => {
    if (!refreshToken) return
    // dispatch(refresh(refreshToken))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const host = window.location.host
  if (isLogin) {
    // return <Navigate to={redirectUrl ?? '/dashboard'} />
      return <Navigate to={'/teams'} />

    // window.location.href = `https://${host}/old/`
  }

  // return !refreshToken ? <Outlet /> : <Loader />
  return <Outlet />
}

export default PublicRoute
