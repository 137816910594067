import Cookies from 'js-cookie'
import { instance } from './interceptors'

const base = 'api/v1/'

export const axiosLogIn = async (data) => {
  try {
    const response = await instance.post(`${base}login`, data)
    const sessionid = response.data.token
    const sessionrole = response.data.user_role
    const userUuid = response.data.user_uuid
    const team_lead_teams = response.data.team_lead_teams
    /*
    1. state.uath.token = responce.data.token
      1.1 satate.auth.role = responce.data.role
    2. cookies session id = responce.data.token
    3.  instance.defaults.headers['Authorization'] = responce.data.token
    */
    Cookies.set('Authorization', sessionid)
    Cookies.set('sessionid', sessionid)
    Cookies.set('sessionrole', sessionrole)
    Cookies.set('sessionuuid', userUuid)
    Cookies.set('teamleadteams', team_lead_teams)
    instance.defaults.headers['Authorization'] = `${sessionid}`
    // console.log('instance', instance.defaults.headers.Authorization)
    return response
  } catch (error) {
    console.log('THIS errir!!!', error.status, error.statusText)
    throw error
  }
}

export const defaultRequest = async (url, type = 'get', body) => {
  try {
    // console.log('@@@@', instance.defaults.headers.Authorization)
    const { data } = await instance[type](url, body)
    return data
  } catch (error) {
    // console.log(error.message)
  }
}

export default instance
