import { createSlice } from '@reduxjs/toolkit'
import { login, refresh, logout } from './auth-operations'

export const initialState = {
  accessToken: '',
  refreshToken: '',
  message: '',
  isLogin: false,
  loading: false,
  isRefreshing: false,
  error: null,
  role: 'admin',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducer: {
    clear: (state) => {
      state.isLogin = false
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false
        state.isLogin = true
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload?.data.message
      })

    builder
      .addCase(refresh.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(refresh.fulfilled, (state, { payload }) => {
        state.loading = false
        state.isLogin = true
        state.accessToken = payload.access
        // state.refreshToken = payload.newRefreshToken
      })
      .addCase(refresh.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload?.data.message
      })

    builder
      .addCase(logout.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false
        state.isLogin = false
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload?.data.message
      })
  },
})

export const { setRole, clear } = authSlice.actions

export default authSlice.reducer
