import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { instance } from '../../api/interceptors'

// import Loader from 'components/common/Loader'

// import { refresh } from 'redux/auth/auth-operations'

// import { constantAll } from 'api/constants'

export let constants = null

const PrivateRoute = () => {
  // const dispatch = useDispatch()
  const { isLogin } = useSelector((state) => state.auth)
  // const [isReady, setIsReady] = useState(false)

  const refreshToken = Cookies.get('refreshToken')

  /*
    1. token = state.auth.token
    2. if not token: 
          token = cookies.get('sessionid')
    3. if not token return false
    4. return true
    */

  let token = useSelector((state) => state.auth.token)
  console.log('state token', token)

  const checkAuthorization = () => {
    if (!token) {
      token = Cookies.get('Authorization')
      instance.defaults.headers['Authorization'] = `${token}`
      console.log('cookie token', token)
    }

    return Boolean(token)
  }

  // const get_constants = async () => {
  //   try {
  //     const data = await constantAll()
  //     if (data.status === 'success') constants = data.data
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setIsReady(true)
  //   }
  // }

  useEffect(() => {
    if (isLogin) {
      // get_constants()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin])

  useEffect(() => {
    if (!isLogin) {
      // dispatch(refresh(refreshToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checkAuthorization()) {
    return <Navigate to="/login" />
  }

  // return isReady ? <Outlet /> : <Loader />
  return <Outlet />
}

export default PrivateRoute
