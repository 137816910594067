import { lazy } from 'react'
import { Route, Navigate } from 'react-router-dom'

const Dashboard = lazy(() => import('pages/admin/dashboard'))
const EmployeesOnProbation = lazy(() => import('pages/admin/employeesOnProbation'))
const AbsentEmployees = lazy(() => import('pages/admin/absentEmployees'))

const Profile = lazy(() => import('pages/admin/profile'))
const ProfileGlobal = lazy(() =>
  import('components/admin/Profile/ProfileGlobal')
)
const ProfileDocuments = lazy(() =>
  import('components/admin/Profile/ProfileDocuments/ProfileDocuments')
)

const ProfileEvents = lazy(() =>
  import('components/admin/Profile/ProfileEvents/index.js')
)
const ProfileRequest = lazy(() =>
  import('components/admin/Profile/ProfileRequest/ProfileRequest')
)
const ProfileDevices = lazy(() =>
  import('components/admin/Profile/ProfileDevices/ProfileDevices')
)
const ProfileNotes = lazy(() =>
  import('components/admin/Profile/ProfileNotes/ProfileNotes')
)

const AddRequest = lazy(() => import('pages/admin/addRequest'))
const EditRequest = lazy(() => import('pages/admin/editRequest'))
const AddEvent = lazy(() => import('pages/admin/addEvent'))
const EditEvent = lazy(() => import('pages/admin/editEvent'))

const Teams = lazy(() => import('pages/admin/allTeams'))
const TeamEmployee = lazy(() => import('components/admin/Team/Employee'))
const AllTeams = lazy(() => import('components/admin/Team/AllTeams'))
const MyTeams = lazy(() => import('components/admin/Team/MyTeams'))
const TeamPage = lazy(() => import('pages/admin/teamPage'))
const AddTeam = lazy(() => import('pages/admin/addTeam'))
const EditTeam = lazy(() => import('pages/admin/editTeam'))
const AddEmployee = lazy(() => import('pages/admin/addEmployee'))
const EditEmployee = lazy(() => import('pages/admin/editEmployee'))
// const Login = lazy(() => import('pages/admin/login'))
// const ForgotPassword = lazy(() => import('pages/admin/forgotPassword'))

const Recruiting = lazy(() => import('pages/admin/recruiting'))
const Vacancies = lazy(() => import('components/admin/Recruiting/RecruitingVacancies/Vacancies'))
const RecruitingDocuments = lazy(() => import('components/admin/Recruiting/RecruitingDocuments/RecruitingDocuments'))
const AddRecruitingDocument = lazy(() => import('pages/admin/addRecruitingDocument'))
const EditRecruitingDocument = lazy(() => import('pages/admin/editReruitingDocument'))
const DocumentProfile = lazy(() => import('pages/admin/documentProfile'))
const Candidates = lazy(() => import('components/admin/Recruiting/RecruitingCandidates/Candidates'))
const AddCandidate = lazy(() => import('pages/admin/addCandidate'))
const EditCandidate = lazy(() => import('pages/admin/editCandidate'))
const CandidateProfile = lazy(() => import('pages/admin/candidateProfile'))
const CandidateInfo = lazy(() => import('components/admin/CandidateProfile/CandidateInfo'))
const CandidateEvents = lazy(() => import('components/admin/CandidateProfile/CandidateEvents'))
const CandidateNotes = lazy(() => import('components/admin/CandidateProfile/CandidateNotes'))
const AddVacancy = lazy(() => import('pages/admin/addVacancy'))
const EditVacancy = lazy(() => import('pages/admin/editVacancy'))
const VacancyProfile = lazy(() => import('pages/admin/vacancyProfile'))
const VacancyProfileDescription = lazy(() => import('components/admin/VacancyProfile/VacancyDescription'))
const VacancyProfileCandidates = lazy(() => import('components/admin/VacancyProfile/VacancyCandidates'))
const VacancyProfileSteps = lazy(() => import('components/admin/VacancyProfile/VacancySteps'))
const VacancyProfileIntegration = lazy(() => import('components/admin/VacancyProfile/VacancyIntegration'))
const VacancyProfileNotes = lazy(() => import('components/admin/VacancyProfile/VacancyNotes'))
const VacancyProfileDocuments = lazy(() => import('components/admin/VacancyProfile/VacancyDocuments'))
const AddDocument = lazy(() => import(`pages/admin/addDocument`))
const EditDocument = lazy(() => import(`pages/admin/editDocument`))
const CandidatesEvents = lazy(() => import('components/admin/Recruiting/RecruitingEvents/CandidatesEvents'))
const AddCandidatesEvent = lazy(() => import('pages/admin/addCandidatesEvent'))
const EditCandidatesEvent = lazy(() => import('pages/admin/editCandidatesEvents'))
const CandidateEventProfile = lazy(() => import('pages/admin/candidateEventProfile'))


const AdminRoutes = (
  <>
    {/* <Route path='/login' element={<Login />} key='/login'/> */}
    {/* <Route path='/forgot-password' element={<ForgotPassword />} key='/forgot-password' /> */}

    <Route path="/dashboard" element={<Dashboard />} key="/dashboard" />
    <Route path="/dashboard/probationList" element={<EmployeesOnProbation />} key="/dashboard" />
    <Route path="/dashboard/absentEmployees" element={<AbsentEmployees />} key="/dashboard" />

    <Route path="/profile" element={<Profile />} key="/profile">
      <Route index element={<Navigate to="/profile/global" />} />
      <Route path="global" element={<ProfileGlobal />} />
      <Route path="global/:uuid" element={<ProfileGlobal />} />
      <Route path="events" element={<ProfileEvents />} />
      <Route path="events/:uuid" element={<ProfileEvents />} />
      <Route path="requests" element={<ProfileRequest />} />
      <Route path="requests/:uuid" element={<ProfileRequest />} />
      <Route path="documents" element={<ProfileDocuments />} />
      <Route path="documents/:uuid" element={<ProfileDocuments />} />
      <Route path="devices" element={<ProfileDevices />} />
      <Route path="devices/:uuid" element={<ProfileDevices />} />
      <Route path="notes" element={<ProfileNotes />} />
      <Route path="notes/:uuid" element={<ProfileNotes />} />
    </Route>

    <Route
      path="/requests/add/:uuid"
      element={<AddRequest />}
      key="/request"
    >
      <Route index element={<Navigate to="/requests" />} />
    </Route>

    <Route
      path="/requests/edit/:uuid"
      element={<EditRequest />}
      key="/request"
    >
      <Route index element={<Navigate to="/requests" />} />
    </Route>

    <Route
      path="/events/add/:uuid"
      element={<AddEvent />}
      key="/event"
    >
      <Route index element={<Navigate to="/events" />} />
    </Route>

    <Route
      path="/events/edit/:uuid"
      element={<EditEvent />}
      key="/event"
    >
      <Route index element={<Navigate to="/events" />} />
    </Route>

    <Route path="/teams" element={<Teams />} key="/teams">
      <Route index element={<Navigate to="/teams/all" />} />
      <Route path="all" element={<AllTeams />} />
      <Route path="my" element={<MyTeams />} />
      <Route path="employee" element={<TeamEmployee />} />
    </Route>
    <Route path="/teams/create" element={<AddTeam />} />
    <Route path="/teams/edit/:uuid" element={<EditTeam />} />
    <Route path="/teams/employee/create" element={<AddEmployee />} />
    <Route path="/teams/employee/edit/:uuid" element={<EditEmployee />} />

    <Route path="/teams/:uuid" element={<TeamPage />} key="/team">
      <Route index element={<Navigate to="/team" />} />
    </Route>


    <Route path="/recruiting" element={<Recruiting />} key="/recruiting">
      <Route index element={<Navigate to="/recruiting/vacancies" />} />
      <Route path="vacancies" element={<Vacancies />} />
      <Route path="documents" element={<RecruitingDocuments />} />
      <Route path="candidates" element={<Candidates />} />
      <Route path="candidates-events" element={<CandidatesEvents />} />
    </Route>

    <Route path="/recruiting/documents/create" element={<AddRecruitingDocument />} />
    <Route path="/recruiting/documents/edit/:uuid" element={<EditRecruitingDocument />} />
    <Route path="/recruiting/document/:uuid" element={<DocumentProfile />} />

    <Route path="/recruiting/vacancies/:uuid" element={<VacancyProfile />} key='/recruiting'>
      <Route index element={<Navigate to='/recruiting/vacancies/:uuid/description'/>}/>
      <Route path='description' element={<VacancyProfileDescription />}/>
      <Route path='candidates' element={<VacancyProfileCandidates />}/>
      <Route path='steps' element={<VacancyProfileSteps />}/>
      <Route path='integration' element={<VacancyProfileIntegration />}/>
      <Route path='notes' element={<VacancyProfileNotes />}/>
      <Route path='documents' element={<VacancyProfileDocuments />}/>
    </Route>

    <Route path="/recruiting/vacancies/create" element={<AddVacancy />} />
    <Route path="/recruiting/vacancies/edit/:uuid" element={<EditVacancy />} />
    <Route path="/recruiting/createDocument/:uuid" element={<AddDocument />} />
    <Route path="/recruiting/editDocument/:uuid" element={<EditDocument />} />
    <Route path="/recruiting/candidates-events/create" element={<AddCandidatesEvent />} />
    <Route path="/recruiting/candidates-events/edit/:uuid" element={<EditCandidatesEvent />} />
    <Route path="/recruiting/candidates-events/:uuid" element={<CandidateEventProfile />} />

    <Route path="/recruiting/candidates/create" element={<AddCandidate />} />
    <Route path="/recruiting/candidates/edit/:uuid" element={<EditCandidate />} />

    <Route path="/recruiting/candidates/:uuid" element={<CandidateProfile />} key='/recruiting'>
      <Route index element={<Navigate to='/recruiting/candidates/:uuid/info'/>}/>
      <Route path='info' element={<CandidateInfo />}/>
      <Route path='events' element={<CandidateEvents />}/>
      <Route path='notes' element={<CandidateNotes />}/>
    </Route>

  </>
)

export default AdminRoutes
