import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'

import App from './components/App'
// import RouterBack from 'components/common/RouterBack'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'

import 'styles/index.css'

// Ініціалізація Sentry має бути винесена з компонента
Sentry.init({
  dsn: 'https://e49f508d7838780a6abaa44b5166fe95@sentry.marksel.tech/4',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  debug: false,
})



const MyComponent = () => {
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = () => {
      setHasError(true)
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])

  // if (hasError) {
  //   return (
  //     <div
  //       onClick={() => {
  //         navigate(-1)
  //         setHasError(false)
  //       }}
  //       className="wrap-error"
  //     >
  //       Something went wrong.
  //       <RouterBack />
  //     </div>
  //   )
  // }

  return <App />
}
const isLocalhost = window.location.hostname === 'localhost'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary fallback={<div>Something went wrong.</div>}>
        <MyComponent />
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>
)

