import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  time: '',
  loading: false,
  error: null,
}

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setTime: (state, { payload }) => {
      state.time = payload
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getTime.pending, (state) => {
  //       state.loading = true
  //       state.error = null
  //     })
  //     .addCase(getTime.fulfilled, (state, { payload }) => {
  //       state.loading = false
  //       state.time = payload
  //     })
  //     .addCase(getTime.rejected, (state, { payload }) => {
  //       state.loading = false
  //       state.error = payload?.data.message
  //     })
})

export const { setLoading, setTime } = infoSlice.actions

export default infoSlice.reducer
