import { createAsyncThunk } from '@reduxjs/toolkit'

import { axiosLogIn } from 'api/auth'
const axiosRefresh = () => {}
const axiosLogout = () => {}

export const login = createAsyncThunk(
  'auth/login',
  async (userData, { rejectWithValue }) => {
    try {
      const data = await axiosLogIn(userData)
      console.log('data', data)
      return data
    } catch (error) {
      const { data, status } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

export const refresh = createAsyncThunk(
  'auth/refresh',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const data = await axiosRefresh(refreshToken)
      return data
    } catch (error) {
      const { data, status } = error.response
      return rejectWithValue({ data, status })
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const data = await axiosLogout()
      return data
    } catch (error) {
      const { data, status } = error.response
      return rejectWithValue({ data, status })
    }
  }
)
