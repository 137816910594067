import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PublicRoute from './PublicRoutes'
import PrivateRoute from './PrivateRoutes'

import Loader from 'components/common/Loader'

import admin from './AdminRoutes'

const Login = lazy(() => import('pages/admin/login'))
const ForgotPassword = lazy(() => import('pages/admin/forgotPassword'))
// const PasswordRecovery = lazy(() => import('pages/auth/PasswordRecovery'))
// const Register = lazy(() => import('pages/auth/Register'))
const NotFoundPage = lazy(() => import('pages/notFoundPage'))

const UserRoutes = () => {
  const { role } = useSelector((state) => state.auth)

  const access = {
    admin,
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} key="/login" />
          {/* <Route path='/forgot-password' element={<ForgotPassword />} key='/forgot-password' /> */}
          {/* <Route path="/register" element={<Register />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} /> */}
        </Route>

        <Route element={<PrivateRoute />}>{access[role]}</Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default UserRoutes
