// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #fff;
  --black: #000;
  --bright: #ced2d8;
  --blue: #0039e3;
  --red: #ff0f6b;
  --green: #aaee00;
  --purple: #733dff;
  --light-purple: #CBB7FF;
  --orange: #fda700;
  --light-grey: #848484;
  --grey-text: #5a5a5a;
  --purple-text: #3A09B9;
}
`, "",{"version":3,"sources":["webpack://./src/styles/colors.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,iBAAiB;EACjB,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[":root {\n  --white: #fff;\n  --black: #000;\n  --bright: #ced2d8;\n  --blue: #0039e3;\n  --red: #ff0f6b;\n  --green: #aaee00;\n  --purple: #733dff;\n  --light-purple: #CBB7FF;\n  --orange: #fda700;\n  --light-grey: #848484;\n  --grey-text: #5a5a5a;\n  --purple-text: #3A09B9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
