import { store } from 'redux/store'
import { addNotification } from 'redux/notificationsSlice'

// export const getCountryByType = (type) => {
//   if (!type) return
//   if (type === 'ALL') return

//   return require(`images/icons/countries/${type}.svg`)
// }


export const errorNotify = (data) => {
  if (data?.status === 'error') {
    const errorList = data?.error
    for (let key in errorList) {
      store.dispatch(
        addNotification({
          type: 'error',
          text: `${key} - ${errorList[key]}`,
        })
      )
    }
  }
}

export const notify = (text, type = 'error') => {
  store.dispatch(
    addNotification({
      type,
      text,
    })
  )
}

export const handleCoppy = (info) => {
  if (info) {
    navigator.clipboard
      .writeText(info)
      .then(() => notify('Coppied!', 'success'))
      .catch((err) => notify(err))
  }
}
